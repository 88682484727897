import { Flex, Text } from 'theme-ui';
import React, { ReactElement } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { Icon } from '@sprinklr/shared-lib/components/icon';

export const FeatureCard = ({
  slug,
  icon,
  title,
  description,
}: {
  description: string;
  title: string;
  icon: string;
  slug: string;
}): ReactElement => {
  return (
    <GatsbyLink
      to={slug}
      style={{
        textDecoration: 'none',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          padding: '12px',
          maxWidth: '316px',
          background: '#ffffff',
          borderRadius: '12px',
          gap: 2,
          boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
          ':hover': {
            background: '#f8f8f8',
          },
        }}
      >
        <Flex
          sx={{
            height: '24px',
            width: '24px',
            borderRadius: '100%',
            background: '#107EFF',
            alignItems: 'center',
            justifyContent: 'center',
            flex: '0 0 auto',
          }}
        >
          <Icon
            iconSx={{ height: '12px', width: '12px', fill: 'white' }}
            iconName={icon}
          />
        </Flex>
        <Flex sx={{ flexDirection: 'column', gap: 1, flex: '1 1 0%' }}>
          <Text
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: 'bold',
              lineHeight: '20px',
              textDecoration: 'none',
            }}
          >
            {title}
          </Text>
          <Text
            sx={{
              color: 'sprText02',
              fontSize: '13px',
              fontWeight: 'regular',
              lineHeight: '20px',
              textDecoration: 'none',
            }}
          >
            {description}
          </Text>
        </Flex>
      </Flex>
    </GatsbyLink>
  );
};
