import React, { ReactElement } from 'react';

import { Box, Grid, Flex } from 'theme-ui';
import { FeatureCard } from './FeatureCard';
import { CallToAction, Title } from '@sprinklr/shared-lib';
import { Props } from './types';
import { makeFeaturePageSlug } from '../../../utils/makeFeaturePageSlug';
import { ensureLeadingAndTrailingSlash } from '@sprinklr/shared-lib/utils/getPaginatedSlugs';

const FeatureGrid = (props: Props): ReactElement => {
  const { title, features, ctAs, internalPageLinks } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: ['20px', '32px', '72px'],
      }}
    >
      <Title title={title} />
      <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Grid sx={{ gridGap: '24px' }} columns={[1, 2, 3]} gap={6}>
          {internalPageLinks?.length > 0
            ? internalPageLinks.map(pageLink => (
                <FeatureCard
                  key={pageLink.id}
                  title={pageLink.title}
                  description={pageLink.description}
                  icon={pageLink.icon.icon}
                  slug={ensureLeadingAndTrailingSlash(pageLink.slug)}
                />
              ))
            : features.map(feature => (
                <FeatureCard
                  key={feature.id}
                  title={feature.title}
                  description={feature.description}
                  icon={feature.icon}
                  slug={makeFeaturePageSlug({
                    productSlug: feature.product.slug,
                    featureSlug: feature.slug,
                  })}
                />
              ))}
        </Grid>
      </Box>
      {ctAs?.length > 0 ? (
        <Flex
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '24px',
            justifyContent: 'center',
          }}
        >
          {ctAs.map(cta => (
            <CallToAction key={cta.id} {...cta}></CallToAction>
          ))}
        </Flex>
      ) : null}
    </Box>
  );
};

FeatureGrid.displayName = 'FeatureGrid';
export default FeatureGrid;
